import Qs from "qs";
import Axios, { AxiosRequestConfig, AxiosError } from "axios";
import { envConfig } from "config";
import { getCookieValue, AuthCookies } from "@akj-dev/affinity-platform";

interface RequestConfig extends AxiosRequestConfig {
  skip_jwt_injection?: boolean;
}

const ajaxInstance = Axios.create({
  baseURL: envConfig.baseUrl,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "comma" }),
});

export const ajaxService = <T>(
  config: RequestConfig,
  options?: RequestConfig
): Promise<T> => {
  ajaxInstance.defaults.headers.post["Content-Type"] = "application/json";
  ajaxInstance.defaults.headers.put["Content-Type"] = "application/json";
  ajaxInstance.interceptors.request.use(requestIntercept, (error) =>
    Promise.reject(error)
  );
  return ajaxInstance({ ...config, ...options }).then(({ data }) => data);
};

const requestIntercept = async (request: RequestConfig) => {
  const token = getCookieValue(AuthCookies.SESSION_TOKEN);
  if (!request.skip_jwt_injection && token && request?.headers) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

export type ErrorType<Error> = AxiosError<Error>;
