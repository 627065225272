import React from "react";
import {
  useGetChargesReport,
  useGetChargesReportDownload,
} from "api/generated/customer/customer";
import { ChargesReport } from "api/generated/schemas";
import { ReportTemplate } from "screens/customer/reports/ReportTemplate";
import { ReportDateFormats } from "screens/customer/reports/constants/dateFormats";
import { ReportIds } from "screens/customer/reports/constants/reportIds";

const columns: (keyof ChargesReport)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "chargeType",
  "chargeDescription",
  "amount",
  "monthlyRate",
  "invoiceNumber",
  "billSequenceNumber",
  "invoiceDate",
  "ban",
  "allocationCode1",
  "allocationCode2",
  "allocationCode3",
  "allocationCode4",
  "chargeStartDate",
  "chargeEndDate",
];

const defaultColumns: (keyof ChargesReport)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "chargeType",
  "chargeDescription",
  "amount",
  "monthlyRate",
];

export const Charges = () => {
  return (
    <ReportTemplate
      id={ReportIds.charges}
      columns={columns}
      defaultColumns={defaultColumns}
      showCLiFilter={true}
      showPeriodsFilter={true}
      showDateRangeFilter={true}
      reportQueryHook={useGetChargesReport}
      reportDownloadHook={useGetChargesReportDownload}
      dateFormat={ReportDateFormats.charges}
    />
  );
};
