import React, { Dispatch, SetStateAction } from "react";
import { MUIDataTableColumn, MUIDataTableColumnOptions } from "mui-datatables";
import {
  BilledUsage,
  ChargesReport,
  LiveService,
  Page,
  PhoneSummary,
  PlexusCallReport,
} from "api/generated/schemas";
import {
  PaginatedTable,
  PaginatedTableProps,
} from "components/organisms/PaginatedTable/PaginatedTable";
import { Pagination } from "components/organisms/PaginatedTable/hooks/usePagination";
import { format, parse } from "date-fns";
import { columnLabels } from "screens/customer/reports/constants/columnLabels";
import { toCurrency } from "helpers/utils";

// Reports share a lot of common properties, so we don't want a separate table
// component for each one.
type ReportItem = ChargesReport | PlexusCallReport | PhoneSummary;
type ReportItemKeys = (
  | keyof ChargesReport
  | keyof PlexusCallReport
  | keyof PhoneSummary
  | keyof BilledUsage
  | keyof LiveService
)[];

export interface ReportTableProps {
  id?: PaginatedTableProps["id"];
  columns: ReportItemKeys;
  defaultColumns: ReportItemKeys;
  data?: { _data: ReportItem[]; _page: Page };
  count?: number;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  dateFormat?: string;
  isLoading?: boolean;
}

export const ReportTable = ({
  id,
  columns,
  defaultColumns,
  data,
  pagination,
  setPagination,
  dateFormat = "dd/MM/yyyy",
  isLoading,
}: ReportTableProps) => {
  const tableColumns: MUIDataTableColumn[] = columns.map((col) => ({
    name: col,
    label: columnLabels[col],
    options: {
      customBodyRender: (val, meta) => {
        const header = meta.columnData?.label || meta.columnData.name;
        let output = val;
        if (
          header.includes("£") ||
          header.includes("Cost") ||
          header.includes("Discount") ||
          header.includes("Amt Eligible For Tax Code") ||
          ["Duration (Min)", "Call Profile (%)", "Revenue (%)"].includes(header)
        ) {
          output = toCurrency(output);
        }
        if (header.includes("Date") || header.includes("Period")) {
          const inputDateFormat = "yyyy-MM-dd";
          const inputDateRegex = new RegExp(
            /\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/g
          );
          const matches = val.matchAll(inputDateRegex);
          for (const m of matches) {
            const match = m[0];
            try {
              const formatted = format(
                parse(match, inputDateFormat, new Date()),
                dateFormat
              );
              output = output.replace(match, formatted);
            } catch (e) {}
          }
        }
        return output;
      },
      display: (defaultColumns.includes(col)
        ? "true"
        : "false") as MUIDataTableColumnOptions["display"],
    },
  }));

  return (
    <PaginatedTable
      id={id}
      columns={tableColumns}
      data={data}
      pagination={pagination}
      setPagination={setPagination}
      isLoading={isLoading}
    />
  );
};
