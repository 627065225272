import React from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { SearchCustomers } from "screens/search-customers/SearchCustomers";
import { Customer } from "screens/customer/Customer";
import { Invoices } from "screens/customer/invoices/Invoices";
import { Reports } from "screens/customer/reports/Reports";
import { Charges } from "screens/customer/reports/charges/Charges";
import { BilledUsageReport } from "screens/customer/reports/billed-usage/BilledUsageReport";
import { PhoneSummaryReport } from "screens/customer/reports/phone-summary/PhoneSummaryReport";
import { PlexusCallProfile } from "screens/customer/reports/plexus-call-profile/PlexusCallProfile";
import { AppContainer } from "components/templates/AppContainer";
import { LiveServices } from "screens/customer/reports/live-services/LiveServices";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/billing" element={<AppContainer />}>
        <Route index element={<SearchCustomers />} />
        <Route path="customer/:ban" element={<Customer />}>
          <Route path="invoices" element={<Invoices />} />
          <Route path="reports" element={<Reports />}>
            <Route path="charges" element={<Charges />} />
            <Route path="billed-usage" element={<BilledUsageReport />} />
            <Route path="live-services" element={<LiveServices />} />
            <Route path="phone-summary" element={<PhoneSummaryReport />} />
            <Route path="plexus-call-profile" element={<PlexusCallProfile />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
