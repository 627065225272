import { cleanEnv, str } from "envalid";

export const env = cleanEnv(process.env, {
  REACT_APP_STAGE: str({ default: "" }),
  BILLING_FE_AUTH_API: str({
    default: "https://pub-external-users-testenv02.uat.aurora.io/api/v2/",
  }),
  BILLING_FE_USERNAME: str({ default: "" }),
  BILLING_FE_PASSWORD: str({ default: "" }),
});
