import React from "react";

import { useGetCustomers } from "api/generated/customer/customer";

import { Main } from "components/templates/Main";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import { NoResults } from "components/molecules/NoResults";
import { usePagination } from "components/organisms/PaginatedTable/hooks/usePagination";

import { useDebounce } from "hooks/useDebounce";

import { useAppState, useSearchActions } from "adapters/storeAdapter";

import { CustomerSearchBar } from "screens/search-customers/CustomerSearchBar";
import { CustomersTable } from "screens/search-customers/CustomersTable";

export const SearchCustomers = () => {
  const searchTerm = useAppState("searchTerm");
  const { setSearchTerm } = useSearchActions();
  const debouncedTerm = useDebounce(searchTerm, 500);
  const [pagination, setPagination] = usePagination();
  const { data, error, isLoading } = useGetCustomers({
    term: debouncedTerm,
    ...pagination,
  });

  const renderErrorLoading = () => {
    if (error) return <ErrorAlert message={error.message} />;
    if (!isLoading && !data?._data?.length) return <NoResults />;
    return null;
  };

  return (
    <Main title="Invoices & Reports">
      <CustomerSearchBar term={searchTerm} setTerm={setSearchTerm} />
      {renderErrorLoading() || (
        <CustomersTable
          isLoading={isLoading}
          data={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </Main>
  );
};
