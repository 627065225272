export const isArray = (val: unknown) => Array.isArray(val);

export const isObject = (val: unknown) =>
  typeof val === "object" && !isArray(val) && val !== null;

export const isEmpty = (val: unknown) => {
  if (typeof val === "number") return false;
  if (Array.isArray(val)) return !val.length;
  return !val;
};

export const toCurrency = (value: any, hasPoundSymbol?: boolean) => {
  if (typeof value === "number") {
    return `${hasPoundSymbol ? "£" : ""}${value.toFixed(2)}`;
  }
  return value;
};
