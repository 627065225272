import { useSearchParams } from "react-router-dom-v5-compat";
import React, { useRef, useState } from "react";
import { Filter, FilterType, FetchResourcesHook } from "./types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Box,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import DateRangeFilter from "components/organisms/Filter/FilterTypes/DateRangeFilter";
import ListFilter from "components/organisms/Filter/FilterTypes/ListFilter";
import { serializeSearchParams } from "helpers/serializeSearchParams";

interface FilterItemProps {
  item: Filter;
  fetchResourcesHook: FetchResourcesHook;
  renderCustomFilterTypes?: Function;
}

export const FilterItem = ({
  item,
  fetchResourcesHook,
  renderCustomFilterTypes,
}: FilterItemProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const anchorRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selection, setSelection] = useState<string[]>(
    item?.value ? item.value.split(",") : []
  );

  const openDropdown = () => {
    let selectedValues: string[] = [];
    searchParams.forEach((value, key) => {
      if (key === item.id) {
        selectedValues = value.split(",");
      }
    });
    setSelection(selectedValues);
    setShowPopup(!showPopup);
  };

  const resetParamsQuery = () => {
    const params: string[] = [];

    searchParams.forEach((value, key) => {
      if (key === item?.id) {
        params.push(key);
      }
    });
    params.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(serializeSearchParams(searchParams));
  };

  const addFilter = () => {
    const newSelection = selection.slice();
    const updated = {
      ...item,
      values: [newSelection?.join(",")],
    };
    resetParamsQuery();
    addFilterParams(updated);
  };

  const addFilterParams = (updated: Filter) => {
    updated?.values?.forEach((value) => {
      searchParams.append(updated?.id, value);
    });
    setSearchParams(serializeSearchParams(searchParams));
    setShowPopup(false);
  };

  const resetFilter = () => {
    resetParamsQuery();
    setSelection([]);
  };

  const closeFilter = () => {
    setSelection([]);
    setShowPopup(false);
  };

  const renderFilterByType = (item: Filter) => {
    let FilterComponent = renderCustomFilterTypes?.(item) || null;
    switch (item.type) {
      case FilterType.LIST: {
        FilterComponent = ListFilter;
        break;
      }
      case FilterType.DATE_RANGE: {
        FilterComponent = DateRangeFilter;
        break;
      }
    }
    if (!FilterComponent) return;
    return (
      <FilterComponent
        key={item?.id}
        item={item}
        options={item.options}
        setSelection={setSelection}
        selection={selection}
        addSelection={addFilter}
        resetSelection={resetFilter}
        close={closeFilter}
        fetchResourcesHook={fetchResourcesHook}
      />
    );
  };

  const onFilterAwayClick = (e: any) => {
    const filterBtn = e.path.find((e: any) => {
      return e.attributes?.[3]?.nodeValue === `filter-button-${item.id}`;
    });
    if (filterBtn) return; //@hack - select portal fires clickaway
    setShowPopup(false);
  };

  return (
    <Box ref={anchorRef} className="filter-item">
      <Button
        variant="text"
        color="secondary"
        sx={styles.itemButton}
        onClick={openDropdown}
        data-id={`filter-button-${item.id}`}
        aria-describedby="filter-popup"
      >
        {item.name}
        {showPopup ? <ExpandLess /> : <ExpandMore />}
      </Button>
      <Popper
        open={showPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={false}
        sx={styles.popper}
        placement="bottom-start"
        id="filter-popup"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={onFilterAwayClick}>
                <Box sx={styles.filterItem}>{renderFilterByType(item)}</Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const styles: SxStyleSheet = {
  popper: {
    zIndex: 1100,
  },
  filterItem: {
    minWidth: 250,
    maxWidth: 500,
    padding: "0px",
  },
  itemButton: {
    fontWeight: 600,
    fontSize: "0.95rem",
  },
};
