import React from "react";
import { Box, Card, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { SxStyleSheet } from "providers/UIProvider";

interface CustomerSearchBarProps {
  term: string;
  setTerm: (term: string) => void;
}

export const CustomerSearchBar = ({
  term,
  setTerm,
}: CustomerSearchBarProps) => {
  return (
    <Card sx={styles.root}>
      <Box sx={styles.inner}>
        <Search sx={styles.icon} />
        <TextField
          sx={styles.input}
          id="search-customers"
          label="Search Accounts"
          value={term}
          onChange={(event) => setTerm(event.target.value)}
        />
      </Box>
    </Card>
  );
};

const styles: SxStyleSheet = {
  root: {
    width: "100%",
    mt: 2,
    p: 2,
  },
  inner: {
    display: "flex",
    alignItems: "center",
  },
  icon: { color: "action.active", mr: 1, my: 0.5 },
  input: {
    "& .MuiInputBase-root": {
      width: 500,
    },
  },
};
