import React from "react";
import { PlexusCallReport } from "api/generated/schemas";
import { ReportTemplate } from "screens/customer/reports/ReportTemplate";
import {
  useGetPlexusCallReport,
  useGetPlexusCallReportDownload,
} from "api/generated/plexus-call-profile/plexus-call-profile";
import { ReportDateFormats } from "screens/customer/reports/constants/dateFormats";
import { ReportIds } from "screens/customer/reports/constants/reportIds";

const columns: (keyof PlexusCallReport)[] = [
  "rateName",
  "duration",
  "cost",
  "callProfile",
  "revenue",
];

export const PlexusCallProfile = () => {
  return (
    <ReportTemplate
      id={ReportIds.plexusCallProfile}
      columns={columns}
      defaultColumns={columns}
      showCLiFilter={false}
      showPeriodsFilter={true}
      showDateRangeFilter={false}
      periodMaxCount={1}
      reportQueryHook={useGetPlexusCallReport}
      reportDownloadHook={useGetPlexusCallReportDownload}
      dateFormat={ReportDateFormats.plexusCallProfile}
    />
  );
};
