import { useMemo } from "react";

import { useAppState as useRawAppState } from "providers/AppStateProvider/AppStateProvider";
import { State, ActionType } from "providers/AppStateProvider/reducer";

export const useAppState = (stateIdentifier: keyof State) => {
  const { state } = useRawAppState();

  return state[stateIdentifier];
};

export const useSearchActions = () => {
  const { dispatch } = useRawAppState();

  return useMemo(
    () => ({
      setSearchTerm: (searchTerm: string) =>
        dispatch({ type: ActionType.SET_SEARCH_TERM, payload: searchTerm }),
    }),
    [dispatch]
  );
};
