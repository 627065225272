export interface Filter {
  id: string;
  name?: string;
  name_short?: string;
  type?: FilterType;
  resources?: string;
  resources_key?: string;
  from_resources?: string;
  to_resources?: string;
  options?: any;
  values?: string[];
  showToggle?: boolean;
  value?: string;
  valueDisplayFunc?: Function;
}

// TODO: Stronger type
export interface FilterConfig {
  [key: string]: any;
  reset?: boolean;
}

export enum FilterType {
  LIST = "LIST",
  DATE_RANGE = "DATE_RANGE",
}

export type FetchFiltersHook = (config?: FilterConfig) => any;

export type FetchResourcesHook = Function;
