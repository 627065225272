import { rest } from "msw";

export const getAuthMSW = () => [
  rest.get("*/jwt", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json({
        api_url_base: "https://pub-kong-testenv02.uat.aurora.io/",
        expires_local: "2023-01-03T17:31:30",
        expires_utc: "2023-01-03T17:31:30",
        jwt: "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJGMFlaODZWYnZzMFJUdlkwcExIaVF1SFIxN1J6bnJSbyIsImV4dGVybmFsX3VzZXJfaWQiOiI0NjcxIiwiZXhwIjoxNjc5NTgzOTcxLCJleHRlcm5hbF91c2VyX3NvdXJjZSI6IkV4dGVybmFsIFVzZXJzIEFwcCIsImV4dGVybmFsX3VzZXJfbmFtZSI6IlZvZGFmb25lIERlYWxlciAxIiwidXNlcl91dWlkIjoiYmM5YWMzODMtMDY1NS0wMTRiLWE5NjctNjJlMjhjY2IwNzk3In0.SYwa58TpRsExudN8NbthnRGPMed_T-LrtMlNEKkf6n0",
        meta: {
          id: "sales_person-8644",
          name: "Centralized Software Development Ltd",
          type: "sales_person",
        },
        sales_person_id: "sales_person-8644",
        settings: {
          Customer_type_id: "10",
          affinity_support_permission_view_dashboard: "0",
          as_APN: "1",
          as_account_level_bolt_ons_enabled: "1",
          as_airtime_credits_enabled: "1",
          as_blank_sim_enabled: "0",
          as_can_add_bill_options: "1",
          as_can_apply_wwcap: "1",
          as_can_see_product_family: "0",
          as_can_select_o2_products: "1",
          as_can_set_friday_ports: "0",
          as_cirrus_livechat_enabled: "1",
          as_commission_type_selection_for_mobile: "0",
          as_commission_type_selection_for_wlr_bb: "0",
          as_cost_centre_enabled: "1",
          as_create_new_account_as_sub_account: "0",
          as_daisy_fresh_enabled: "0",
          as_ethernet_order_enabled: "0",
          as_generate_contract: "1",
          as_hardware_order_enabled: "0",
          as_mobile_order_enabled: "0",
          as_mobile_resign_enabled: "0",
          as_monitoring_order_enabled: "0",
          as_pre_dispactched_sim_required: "0",
          as_pre_dispatched_sim_required: "0",
          as_quote_generation_enabled: "1",
          as_request_dynamic_products: "1",
          as_require_partner_reference_number: "0",
          as_stop_daisy_line_orders: "1",
          as_supplier_approvals_enabled: "1",
          as_universal_enabled: "1",
          as_wlrbb_order_enabled: "1",
          as_wlrbb_resign_enabled: "0",
          can_upload_supporting_documents: "0",
          can_use_affinity_billing: "1",
          can_use_affinity_customers: "0",
          can_use_affinity_hub: "1",
          can_use_affinity_insight: "1",
          can_use_affinity_orders: "1",
          can_use_affinity_provision: "0",
          can_use_affinity_sales: "1",
          can_use_affinity_support: "1",
          can_use_affinity_support_new: "0",
          can_use_platform_global_search: "0",
          can_use_vfdirect_discount: "0",
          category: "",
          contract_type_id: "7",
          dc_allow_upload_contract: "1",
          dws_reseller_enabled: "0",
          requires_external_approval_for_external_user_orders: "0",
          restrict_product_search_to_vf_direct: "1",
          sales_person_id: "6724",
          should_divorce_sub_accounts_when_contract_signed: "0",
          virtual_reseller_mobile_tariff_sets: "153",
        },
      })
    );
  }),
];
