/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetPhoneSummaryReport200,
  GetPhoneSummaryReportParams,
  ReportDownloadLink,
  GetPhoneSummaryReportDownloadParams,
} from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getPhoneSummaryReport = (
  ban: string,
  params?: GetPhoneSummaryReportParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetPhoneSummaryReport200>(
    {
      url: `/customer/${ban}/phone-summary-report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPhoneSummaryReportQueryKey = (
  ban: string,
  params?: GetPhoneSummaryReportParams
) => [`/customer/${ban}/phone-summary-report`, ...(params ? [params] : [])];

export type GetPhoneSummaryReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPhoneSummaryReport>>
>;
export type GetPhoneSummaryReportQueryError = ErrorType<unknown>;

export const useGetPhoneSummaryReport = <
  TData = Awaited<ReturnType<typeof getPhoneSummaryReport>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetPhoneSummaryReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPhoneSummaryReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPhoneSummaryReportQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPhoneSummaryReport>>
  > = ({ signal }) =>
    getPhoneSummaryReport(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPhoneSummaryReport>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL where the phone summary report is stored
 */
export const getPhoneSummaryReportDownload = (
  ban: string,
  params?: GetPhoneSummaryReportDownloadParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/phone-summary-report/report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPhoneSummaryReportDownloadQueryKey = (
  ban: string,
  params?: GetPhoneSummaryReportDownloadParams
) => [
  `/customer/${ban}/phone-summary-report/report`,
  ...(params ? [params] : []),
];

export type GetPhoneSummaryReportDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPhoneSummaryReportDownload>>
>;
export type GetPhoneSummaryReportDownloadQueryError = ErrorType<unknown>;

export const useGetPhoneSummaryReportDownload = <
  TData = Awaited<ReturnType<typeof getPhoneSummaryReportDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetPhoneSummaryReportDownloadParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPhoneSummaryReportDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPhoneSummaryReportDownloadQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPhoneSummaryReportDownload>>
  > = ({ signal }) =>
    getPhoneSummaryReportDownload(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPhoneSummaryReportDownload>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
