import React from "react";
import { useGetBilledUsageReport } from "api/generated/customer/customer";
import { BilledUsage } from "api/generated/schemas";
import { ReportTemplate } from "screens/customer/reports/ReportTemplate";
import { useGetBilledUsageReportDownload } from "api/generated/billed-usage/billed-usage";
import { ReportIds } from "screens/customer/reports/constants/reportIds";
import { ReportDateFormats } from "screens/customer/reports/constants/dateFormats";

const columns: (keyof BilledUsage)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "cost",
  "usageSubtype",
  "dataVolume",
  "duration",
  "invoiceNumber",
  "billSequenceNumber",
  "invoiceDate",
  "ban",
  "allocationCode1",
  "allocationCode2",
  "allocationCode3",
  "allocationCode4",
  "dialledNumber",
  "destination",
  "callDate",
  "callTime",
  "timePeriod",
  "noOfActivities",
  "uplinkValue",
  "downlinkValue",
  "transmissionType",
  "summaryCategory",
  "usageType",
  "pricingFeatureDescription",
  "bundleType",
  "countryOfOrigin",
  "networkChargeOrigin",
  "roamedCategory",
  "usageDirection",
];

const defaultColumns: (keyof BilledUsage)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "cost",
  "usageSubtype",
  "dataVolume",
  "duration",
];

export const BilledUsageReport = () => {
  return (
    <ReportTemplate
      id={ReportIds.billedUsage}
      columns={columns}
      defaultColumns={defaultColumns}
      showCLiFilter={true}
      showPeriodsFilter={true}
      showDateRangeFilter={true}
      reportQueryHook={useGetBilledUsageReport}
      reportDownloadHook={useGetBilledUsageReportDownload}
      dateFormat={ReportDateFormats.billedUsage}
    />
  );
};
