import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  MenuItem,
  Radio,
  TextField,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import FilterBox from "components/organisms/Filter/FilterBox";
import { Filter, FetchResourcesHook } from "components/organisms/Filter/types";
import { useDebounce } from "hooks/useDebounce";

interface ListFilterProps {
  item: Filter;
  options?: { [key: string]: any };
  selection: string[];
  setSelection: Function;
  addSelection: Function;
  resetSelection: Function;
  close: Function;
  fetchResourcesHook: FetchResourcesHook;
}

export default function ListFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  fetchResourcesHook,
  ...props
}: ListFilterProps) {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const {
    data: filterResponse,
    fetchNextPage: fetchNextFilterPage,
    isLoading,
    hasNextPage,
  } = fetchResourcesHook(item, { search: debouncedSearch });

  const responseKey = item.resources_key || "_data" || "list";

  const [filter, setFilter] = useState<any>();
  useEffect(() => {
    if (filterResponse?.pages?.length) {
      const filterList: any = [];
      filterResponse.pages?.forEach((page: any) => {
        page?.[responseKey]?.forEach((value: any) => {
          filterList.push(value);
        });
      });
      setFilter(filterList);
    }
  }, [filterResponse, responseKey]);

  const handleSelect = (listItem: any) => {
    if (options?.MAX_COUNT === 1) {
      setSelection([listItem]);
      return;
    }
    if (isSelected(listItem)) {
      return setSelection(selection.filter((c: any) => c !== listItem));
    }
    if (selection.length >= options?.MAX_COUNT) {
      return;
    }
    return setSelection([...selection, listItem]);
  };

  const isSelected = (listItem: any) => {
    return selection.includes(listItem);
  };

  const onScroll = (e: any) => {
    const buffer = 2;
    const scrolled = e.target.scrollHeight - e.target.scrollTop;
    const total = e.target.clientHeight + buffer;
    const hitBottom = scrolled <= total;
    if (hitBottom && hasNextPage) {
      fetchNextFilterPage();
    }
  };

  return (
    <Box {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        {Boolean(options?.SEARCH_BAR) && (
          <TextField
            variant="outlined"
            size="small"
            value={search}
            sx={styles.filterSearch}
            placeholder={`Search for ${item.name}`}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        )}
        <Box
          key={item?.name}
          id="scrollableDiv"
          sx={styles.filterBody}
          onScroll={onScroll}
          role="menu"
        >
          {!Boolean(filter?.length) && !isLoading ? (
            <Box sx={styles.loader}>No results found</Box>
          ) : (
            filter?.map((value: any) => {
              return (
                <MenuItem
                  key={value}
                  onClick={() => handleSelect(value)}
                  selected={isSelected(value)}
                  sx={styles.filterListValue}
                >
                  {options?.MAX_COUNT === 1 ? (
                    <Radio checked={isSelected(value)} />
                  ) : (
                    <Checkbox size="small" checked={isSelected(value)} />
                  )}
                  {item.valueDisplayFunc ? item.valueDisplayFunc(value) : value}
                </MenuItem>
              );
            })
          )}
          {isLoading ? (
            <Box sx={styles.loader}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </FilterBox>
    </Box>
  );
}

const styles: SxStyleSheet = {
  filterBody: {
    maxHeight: 210,
    overflowY: "auto",
    paddingInline: 2,
  },
  filterSearch: {
    paddingInline: 2,
    marginBottom: 1,
  },
  filterListValue: {
    textTransform: "capitalize",
    paddingX: 0,
    paddingY: 0,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    flex: 1,
  },
};
