import React from "react";
import { Alert, Box, Card } from "@mui/material";
import {
  useGetBilledUsageReport,
  useGetChargesReport,
  useGetChargesReportDownload,
} from "api/generated/customer/customer";
import { FiltersCard } from "components/organisms/Filter/FiltersCard";
import {
  ReportTableProps,
  ReportTable,
} from "screens/customer/reports/ReportTable";
import { useReportFilters } from "api/queries/useReportFilters";
import { useReportFilterResources } from "api/queries/useReportFilterResources";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import { NoResults } from "components/molecules/NoResults";
import { usePagination } from "components/organisms/PaginatedTable/hooks/usePagination";
import { SxStyleSheet } from "providers/UIProvider";
import { DownloadReport } from "./DownloadReport";
import { useBanParam } from "hooks/useBanParam";
import {
  useGetPhoneSummaryReport,
  useGetPhoneSummaryReportDownload,
} from "api/generated/phone-summary/phone-summary";
import {
  useGetPlexusCallReport,
  useGetPlexusCallReportDownload,
} from "api/generated/plexus-call-profile/plexus-call-profile";
import { useGetBilledUsageReportDownload } from "api/generated/billed-usage/billed-usage";
import { useExclusiveFilterParams } from "screens/customer/reports/hooks/useExclusiveFilterParams";
import { useEffectDangerous } from "hooks/useEffectDangerous";
import {
  useGetLiveServicesReport,
  useGetLiveServicesReportDownload,
} from "api/generated/live-services/live-services";
import { PaginatedTableProps } from "components/organisms/PaginatedTable/PaginatedTable";

interface ReportTemplateProps {
  id?: PaginatedTableProps["id"];
  columns: ReportTableProps["columns"];
  defaultColumns: ReportTableProps["defaultColumns"];
  showCLiFilter: boolean;
  showPeriodsFilter: boolean;
  showDateRangeFilter: boolean;
  periodMaxCount?: number;
  dateFormat?: string;
  reportQueryHook:
    | typeof useGetChargesReport
    | typeof useGetBilledUsageReport
    | typeof useGetLiveServicesReport
    | typeof useGetPhoneSummaryReport
    | typeof useGetPlexusCallReport;
  reportDownloadHook:
    | typeof useGetChargesReportDownload
    | typeof useGetBilledUsageReportDownload
    | typeof useGetPhoneSummaryReportDownload
    | typeof useGetPlexusCallReportDownload
    | typeof useGetLiveServicesReportDownload;
}

export const ReportTemplate = ({
  id,
  columns,
  defaultColumns,
  showCLiFilter,
  showPeriodsFilter,
  showDateRangeFilter,
  periodMaxCount,
  dateFormat,
  reportQueryHook,
  reportDownloadHook,
}: ReportTemplateProps) => {
  const ban = useBanParam();
  const [pagination, setPagination] = usePagination();
  const filters = useExclusiveFilterParams();

  const enabled =
    !showPeriodsFilter || !!(filters.periods || (filters.start && filters.end));

  useEffectDangerous(() => {
    setPagination({ ...pagination, page: 0 });
  }, [filters]);

  // Fetch Report
  const { data, error, isLoading } = reportQueryHook(
    ban,
    {
      ...filters,
      ...pagination,
    },
    { query: { enabled } }
  );

  // Render
  const renderErrorLoading = () => {
    if (error) return <ErrorAlert message={error.message} />;
    if (!isLoading && !data?._data?.length) return <NoResults />;
    return null;
  };

  return (
    <>
      <Box sx={styles.root}>
        <FiltersCard
          fetchHook={useReportFilters}
          fetchResourcesHook={useReportFilterResources}
          config={{
            ban,
            reset: true,
            clis: showCLiFilter,
            periods: showPeriodsFilter,
            periodMaxCount,
            dateRange: showDateRangeFilter,
          }}
        />
      </Box>
      {enabled ? (
        <>
          <Box sx={styles.download}>
            <DownloadReport
              reportDownloadHook={reportDownloadHook}
              {...filters}
            />
          </Box>
          <Card sx={styles.table}>
            {renderErrorLoading() || (
              <ReportTable
                id={id}
                columns={columns}
                defaultColumns={defaultColumns}
                data={data}
                pagination={pagination}
                dateFormat={dateFormat}
                setPagination={setPagination}
                isLoading={isLoading}
              />
            )}
          </Card>
        </>
      ) : (
        <Alert severity="info">Please select an invoice period above.</Alert>
      )}
    </>
  );
};

const styles: SxStyleSheet = {
  root: {
    mt: 2,
  },
  download: {
    mt: 2,
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    my: 2,
  },
};
