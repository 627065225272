import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Card } from "@mui/material";
import { Link } from "react-router-dom-v5-compat";
import { MUIDataTableColumn } from "mui-datatables";
import { Customer, Page } from "api/generated/schemas";
import { Pagination } from "components/organisms/PaginatedTable/hooks/usePagination";
import { PaginatedTable } from "components/organisms/PaginatedTable/PaginatedTable";
import { SxStyleSheet } from "providers/UIProvider";

interface CustomersTableProps {
  data?: { _data: Customer[]; _page: Page };
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  isLoading: boolean;
}

interface column extends MUIDataTableColumn {
  name: keyof Customer;
}

const columns: column[] = [
  {
    name: "ban",
    label: "Account Number",
    options: { sort: true },
  },
  {
    name: "accountName",
    label: "Account Name",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Box sx={styles.actionCell}>
            <div>{value}</div>
            <Button
              component={Link}
              to={`/billing/customer/${tableMeta.rowData[0]}/invoices`}
              aria-label={`Select Customer ${value}`}
            >
              Select
            </Button>
          </Box>
        );
      },
    },
  },
];

export const CustomersTable = ({
  data,
  pagination,
  setPagination,
  isLoading,
}: CustomersTableProps) => {
  return (
    <Box sx={styles.root}>
      <Card>
        <PaginatedTable
          data={data}
          columns={columns}
          pagination={pagination}
          setPagination={setPagination}
          isLoading={isLoading}
        />
      </Card>
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: {
    my: 2,
    width: "100%",
  },
  actionCell: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
