import React from "react";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { useBanParam } from "hooks/useBanParam";
import { useGetPdfInvoice } from "api/generated/pdf-invoice/pdf-invoice";

interface Props {
  billNumber: string;
  downloadHook: typeof useGetPdfInvoice;
  label: string;
  ben: string;
}

export const PreviewMenuItem = ({
  billNumber,
  downloadHook,
  label,
  ben,
}: Props) => {
  const ban = useBanParam();
  const { error, isFetching, refetch } = downloadHook(
    ban,
    billNumber,
    { ben: ben },
    {
      query: {
        enabled: false,
        cacheTime: 0,
        onSuccess: (data) => {
          if (data && data.link && window && window.open) {
            const tab = window.open(data.link, "_blank");
            if (tab) tab.focus();
          }
        },
      },
    }
  );

  if (error) return <ErrorAlert message={error.message} />;

  return (
    <MenuItem disabled={isFetching} onClick={() => refetch()} sx={styles.root}>
      {isFetching ? (
        <ListItemIcon>
          <CircularProgress size={24} />
        </ListItemIcon>
      ) : (
        <ListItemText>{label}</ListItemText>
      )}
    </MenuItem>
  );
};

const styles: SxStyleSheet = {
  root: { "&.Mui-disabled": { opacity: 1 } },
};
