export enum ActionType {
  SET_SEARCH_TERM,
}

// I figure we'll probably have more global state than this in the end,
// so may as well have a reducer.
export type Action = { type: ActionType.SET_SEARCH_TERM; payload: string };

export type State = {
  searchTerm: string;
};

export const initialState: State = {
  searchTerm: "",
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};
