import React from "react";
import { Box, Card, Tabs, Tab } from "@mui/material";
import { useGetCustomers } from "api/generated/customer/customer";
import { getCompanyName } from "api/helpers/customer";
import { BackLink } from "components/atoms/BackLink";
import { Link, Outlet, useMatch } from "react-router-dom-v5-compat";
import { Main } from "components/templates/Main";
import { SxStyleSheet } from "providers/UIProvider";
import { useBanParam } from "hooks/useBanParam";
import { getDefaultReportParams } from "helpers/getDefaultReportParams";

export const Customer = () => {
  const ban = useBanParam();
  const match = useMatch("billing/customer/:ban/:tab/*");
  const { data } = useGetCustomers({
    term: ban,
  });

  const companyName = getCompanyName(data, ban) || "";

  return (
    <Main title={`${companyName || ""} (#${ban})`}>
      <Box sx={styles.link}>
        <BackLink to="/billing/" />
      </Box>
      <Card sx={styles.card}>
        <Tabs
          value={match?.params.tab || ""}
          variant="fullWidth"
          component="nav"
        >
          <Tab
            label="Invoices"
            value="invoices"
            to="invoices"
            component={Link}
          />
          <Tab
            label="Reports"
            value="reports"
            to={`reports/charges?${getDefaultReportParams()}`}
            component={Link}
          />
        </Tabs>
        <Outlet />
      </Card>
    </Main>
  );
};

const styles: SxStyleSheet = {
  card: { width: "100%" },
  link: {
    mb: 2,
  },
};
