import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react";
import {
  State,
  Action,
  reducer,
  initialState,
} from "providers/AppStateProvider/reducer";

interface AppStateContext {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const Context = createContext({} as AppStateContext);
export const useAppState = () => useContext(Context);

export const AppStateProvider = ({ children }: PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: AppStateContext = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
