/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetLiveServicesReport200,
  GetLiveServicesReportParams,
  ReportDownloadLink,
  GetLiveServicesReportDownloadParams,
} from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getLiveServicesReport = (
  ban: string,
  params?: GetLiveServicesReportParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetLiveServicesReport200>(
    {
      url: `/customer/${ban}/live-services-report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetLiveServicesReportQueryKey = (
  ban: string,
  params?: GetLiveServicesReportParams
) => [`/customer/${ban}/live-services-report`, ...(params ? [params] : [])];

export type GetLiveServicesReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLiveServicesReport>>
>;
export type GetLiveServicesReportQueryError = ErrorType<unknown>;

export const useGetLiveServicesReport = <
  TData = Awaited<ReturnType<typeof getLiveServicesReport>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetLiveServicesReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLiveServicesReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLiveServicesReportQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLiveServicesReport>>
  > = ({ signal }) =>
    getLiveServicesReport(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getLiveServicesReport>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL to download the live services report
 */
export const getLiveServicesReportDownload = (
  ban: string,
  params?: GetLiveServicesReportDownloadParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/live-services-report/report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetLiveServicesReportDownloadQueryKey = (
  ban: string,
  params?: GetLiveServicesReportDownloadParams
) => [
  `/customer/${ban}/live-services-report/report`,
  ...(params ? [params] : []),
];

export type GetLiveServicesReportDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLiveServicesReportDownload>>
>;
export type GetLiveServicesReportDownloadQueryError = ErrorType<unknown>;

export const useGetLiveServicesReportDownload = <
  TData = Awaited<ReturnType<typeof getLiveServicesReportDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetLiveServicesReportDownloadParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLiveServicesReportDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetLiveServicesReportDownloadQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLiveServicesReportDownload>>
  > = ({ signal }) =>
    getLiveServicesReportDownload(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getLiveServicesReportDownload>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
