import React from "react";
import { CloudDownload } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useGetChargesReportDownload } from "api/generated/customer/customer";
import { GetChargesReportDownloadParams } from "api/generated/schemas";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import { useBanParam } from "hooks/useBanParam";
import { useGetBilledUsageReportDownload } from "api/generated/billed-usage/billed-usage";
import { useGetPhoneSummaryReportDownload } from "api/generated/phone-summary/phone-summary";
import { useGetPlexusCallReportDownload } from "api/generated/plexus-call-profile/plexus-call-profile";

interface DownloadReportProps extends GetChargesReportDownloadParams {
  reportDownloadHook:
    | typeof useGetChargesReportDownload
    | typeof useGetBilledUsageReportDownload
    | typeof useGetPhoneSummaryReportDownload
    | typeof useGetPlexusCallReportDownload;
}

export const DownloadReport = ({
  reportDownloadHook,
  periods,
  clis,
  start,
  end,
}: DownloadReportProps) => {
  const ban = useBanParam();
  const { error, isFetching, refetch } = reportDownloadHook(
    ban,
    {
      periods,
      clis,
      end,
      start,
    },
    {
      query: {
        enabled: false,
        cacheTime: 0,
        onSuccess: (data) => {
          if (data?.link) window.location.href = data.link;
        },
      },
    }
  );

  if (error) return <ErrorAlert message={error.message} />;
  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={
        isFetching ? <CircularProgress size={16} /> : <CloudDownload />
      }
      disabled={isFetching}
      onClick={() => refetch()}
    >
      Download Report (CSV)
    </Button>
  );
};
