import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableColumnDef,
  MUIDataTableOptions,
  MUIDataTableState,
} from "mui-datatables";
import { Pagination } from "./hooks/usePagination";
import { Box, LinearProgress, Typography } from "@mui/material";
import { LoadingIndicator } from "components/atoms/LoadingIndicator";
import { SxStyleSheet } from "providers/UIProvider";
import { Page } from "api/generated/schemas";
import { getCacheItem, setCacheItem } from "services/cacheService";
import { isEmpty, isObject } from "helpers/utils";

export interface PaginatedTableProps {
  id?: string;
  pagination: Pagination;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  columns: MUIDataTableColumn[];
  data?: GenericTableResponse200;
  options?: MUIDataTableOptions;
  isLoading?: boolean;
}

type TableData = Array<object | number[] | string[]>;

type GenericTableResponse200 = {
  _data: TableData;
  _page: Page;
};

export const PaginatedTable = ({
  id,
  pagination,
  setPagination,
  columns,
  data,
  options,
  isLoading,
}: PaginatedTableProps) => {
  const [constantData, setConstantData] = useState<GenericTableResponse200>({
    _data: [],
    _page: {},
  });

  useEffect(() => {
    if (data?._data?.length) {
      setConstantData(data);
    }
  }, [data]);

  const onTableChange = (action: string, tableState: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPagination({ ...pagination, page: tableState.page });
        break;
      case "changeRowsPerPage":
        setPagination({ ...pagination, size: tableState.rowsPerPage });
        break;
    }
  };

  const onColumnViewChange = (name: string, action: string) => {
    if (id) {
      const cached = getCacheItem(id);
      const updated = {
        ...cached,
        [name]: { display: action === "add" },
      };
      setCacheItem(id, updated);
    }
  };

  useEffect(() => {
    if (data?._data?.length) {
      setConstantData(data);
    }
  }, [data]);

  const parsedCols = useMemo(() => {
    if (pagination) {
    } // @hack - force columns to re-render when pagination changes
    const cached: { [key: string]: any } = id && getCacheItem(id);
    return columns.map((col) => {
      let display = col.options?.display === "false" ? "false" : "true";
      if (isObject(cached) && Object.keys(cached).length) {
        const cachedCol = cached[col.name];
        if (cachedCol) {
          display = Boolean(cachedCol.display).toString();
        }
      }
      return {
        ...col,
        options: {
          ...col.options,
          customBodyRender: (...params) => {
            if (isEmpty(params[0])) {
              return (
                <Typography variant="body2" color="lightgrey">
                  N/A
                </Typography>
              );
            }
            return col.options?.customBodyRender?.(...params) || params[0];
          },
          display,
        },
      };
    }) as MUIDataTableColumnDef[];
  }, [columns, id, pagination]);

  if (!constantData._data.length && isLoading) return <LoadingIndicator />;

  return (
    <Box sx={styles.root}>
      <MUIDataTable
        columns={parsedCols}
        data={constantData._data}
        title=""
        options={{
          page: pagination.page,
          rowsPerPage: pagination.size,
          selectableRows: "none",
          filter: false,
          search: false,
          sort: false,
          elevation: 0,
          download: false,
          print: false,
          count: constantData._page.total,
          onTableChange,
          onColumnViewChange,
          serverSide: true,
          viewColumns: parsedCols?.length > 4,
          ...options,
        }}
      />
      {isLoading && <LinearProgress />}
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: { position: "relative" },
};
