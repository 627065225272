import React from "react";
import { Alert, Box } from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";

export const NoResults = () => {
  return (
    <Box sx={styles.root}>
      <Alert severity="info">Sorry, no results found.</Alert>
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: {
    padding: 2,
    width: "100%",
  },
};
