import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { LoadingIndicator } from "components/atoms/LoadingIndicator";
import { SxStyleSheet } from "providers/UIProvider";

interface MainProps {
  children: ReactNode;
  isLoading?: boolean;
  title?: string;
}

export function Main({ title, isLoading, children }: MainProps) {
  return (
    <Box sx={styles.root}>
      {Boolean(title) && (
        <Box sx={styles.title} component="header">
          <Typography variant="h2" fontWeight="bold">
            {title}
          </Typography>
        </Box>
      )}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Box sx={styles.content} component="main">
          {children}
        </Box>
      )}
    </Box>
  );
}

const styles: SxStyleSheet = {
  root: {
    py: 3,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    mb: 2,
  },
  content: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
};
