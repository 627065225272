export const getCacheItem = (key: string) => {
  const item = localStorage.getItem(key);
  try {
    const parsed = JSON.parse(item || "");
    return parsed;
  } catch (e) {
    return item;
  }
};

export const setCacheItem = (key: string, value: string | object): void => {
  return localStorage.setItem(
    key,
    typeof value === "object" ? JSON.stringify(value) : value
  );
};
