import React from "react";
import { createRoutingObject } from "@akj-dev/shared-components";
import { Customer } from "screens/customer/Customer";
import { SearchCustomers } from "screens/search-customers/SearchCustomers";

export type CustomerParams = {
  ban: string;
};

export const routes = createRoutingObject(
  [
    {
      path: "/",
      title: "Invoices & Reports",
      subject: <SearchCustomers />,
    },
    {
      path: "/customer/:ban",
      title: "Customer",
      subject: <Customer />,
      hidden: true,
    },
  ],
  "/billing"
);
