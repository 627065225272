/**
 * Convert URLSearchParams iterator to an object
 * @param searchParams
 */
export const serializeSearchParams = (searchParams: URLSearchParams) => {
  const params: any = {};
  searchParams.forEach((v, k) => {
    params[k] = v;
  });
  return params;
};
