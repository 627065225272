import { useInfiniteQuery } from "@tanstack/react-query";
import { useGetClisInfinite } from "api/generated/customer/customer";
import { Filter } from "components/organisms/Filter/types";
import format from "date-fns/format";
import sub from "date-fns/sub";

export const useReportFilterResources = (
  filter: Filter,
  params?: { [key: string]: any }
) => {
  const parts = filter.resources?.split("/")?.filter(Boolean) || [];

  // cli resources
  if (parts[0] === "customer" && parts[2] === "cli") {
    return useGetClisInfinite.call(
      this,
      parts[1],
      { term: params?.search },
      {
        query: {
          getNextPageParam: ({ _data, _page }: any) => {
            if (_data?.length < _page.size) {
              return undefined;
            } else {
              return _page.current + 1;
            }
          },
        },
      }
    );
  }

  // period resources
  if (parts[0] === "periods") {
    return useInfiniteQuery.call(this, ["periods"], async () => {
      const months = 6;
      return {
        _data: Array(months)
          .fill(new Date())
          .map((date, index) => {
            return format(sub(date, { months: index }), "yyyy-MM");
          }),
      };
    });
  }

  return { _data: [] };
};
