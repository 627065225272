export const columnLabels = {
  id: "",
  // Charges
  invoiceNumber: "Invoice Number",
  billSequenceNumber: "Bill Seq Number",
  invoicePeriod: "Invoice Period",
  invoiceDate: "Invoice Date",
  ban: "Account (BAN)",
  ben: "Sub Account (BEN)",
  phoneNumber: "Phone Number",
  username: "User Name",
  allocationCode1: "Allocation Code 1",
  allocationCode2: "Allocation Code 2",
  allocationCode3: "Allocation Code 3",
  allocationCode4: "Allocation Code 4",
  chargeStartDate: "Charge Start Date",
  chargeEndDate: "Charge End Date",
  chargeType: "Charge Type",
  chargeDescription: "Charge Description",
  amount: "Charge Amount (£)",
  monthlyRate: "Monthly Rate (£)",

  // Plexus specific
  rateName: "Rate Name",
  duration: "Duration",
  cost: "Cost (£)",
  callProfile: "Call Profile (%)",
  revenue: "Revenue (%)",

  // Phone summary specific
  pricePlanCode: "Price Plan Code",
  pricePlanDescription: "Price Plan Description",
  numberOfCalls: "Number Of Calls",
  messages: "Messages",
  dataVolume: "Data Volume (MB)",
  recurringCharges: "Recurring Charges (£)",
  otherCharges: "Other Charges (£)",
  usageCharges: "Usage Charges (£)",
  recurringCredits: "Recurring Credits (£)",
  otherCredits: "Other Credits (£)",
  usageCredits: "Usage Credits (£)",
  totalCredits: "Total Credits (£)",
  netTotal: "Net Total (£)",
  amountEligibleForTaxCode1: "Amt Eligible For Tax Code 1",
  amountEligibleForTaxCode2: "Amt Eligible For Tax Code 2",
  amountEligibleForTaxCode3: "Amt Eligible For Tax Code 3",
  amountEligibleForTaxCode4: "Amt Eligible For Tax Code 4",
  vatTotal: "Vat Total (£)",
  grossTotal: "Gross Total (£)",
  ukCallsCost: "Calls While In The UK (Cost) (£)",
  ukCallsVolume: "Calls While In The UK (Volume)",
  ukCallsDuration: "Calls While In The UK (Duration)",
  roamedCallsCost: "Roamed Calls (Cost) (£)",
  roamedCallsVolume: "Roamed Calls (Volume)",
  roamedCallsDuration: "Roamed Calls (Duration)",
  ukMessagesCost: "Messaging While In The UK (Cost) (£)",
  ukMessagesVolume: "Messaging While In The UK (Volume)",
  roamedMessagesCost: "Roamed Messaging (Cost) (£)",
  roamedMessagesVolume: "Roamed Messaging (Volume)",
  ukMobileDataCost: "Mobile Browsing & Data While In The UK (Cost) (£)",
  ukMobileDataVolume:
    "Mobile Browsing & Data While In The UK (Data Volume) (MB)",
  roamedMobileDataCost: "Roamed Mobile Browsing & Data (Cost) (£)",
  roamedMobileDataVolume: "Roamed Mobile Browsing & Data (Data Volume) (MB)",
  purchasesCost: "Purchases (Cost) (£)",
  purchasesVolume: "Purchases (Volume)",

  // Billed Usage specific
  dialledNumber: "Dialled Number",
  destination: "Destination",
  callDate: "Call Date",
  callTime: "Call Time",
  timePeriod: "Time Period",
  noOfActivities: "Number Of Activities",
  uplinkValue: "Uplink Value (Bytes)",
  downlinkValue: "Downlink Value (Bytes)",
  transmissionType: "Transmission Type",
  summaryCategory: "Summary Category",
  usageType: "Usage Type",
  usageSubtype: "Usage Subtype",
  pricingFeatureDescription: "Pricing Feature Description",
  bundleType: "Bundle Type",
  countryOfOrigin: "Country Of Origin",
  networkChargeOrigin: "Network Charge Origin",
  roamedCategory: "Roamed Country",
  usageDirection: "Usage Direction",

  // Live Service specific
  subPartnerNumber: "Sub Partner Number",
  subPartnerName: "Sub Partner Name",
  customerName: "Customer Name",
  ctn: "CTN",
  status: "Status",
  userName: "Username",
  tariffId: "Tariff ID",
  tariffName: "Tariff Name",
  tariffCost: "Tariff Cost",
  tariffDiscount: "Tariff Discount",
  parentCtn: "Parent CTN",
  sim: "SIM",
  contractStartDate: "Contract Start Date",
  contractEndDate: "Contract End Date",
  contractTerm: "Contract Term",
  fullTermEligible: "Full Term Eligible",
  renewalDateFullTerm: "Renewal Date Full Term",
  inLifeEligible: "InLife Eligible",
  renewalDateInLife: "Renewal Date InLife",
  boltOnId: "BoltOn ID",
  boltOnName: "BoltOn Name",
  boltOnCost: "BoltOn Cost",
  boltOnEffectiveDate: "BoltOn Effective Date",
  boltOnExpiryDate: "BoltOn Expiry Date",
  boltOnDiscount: "BoltOn Discount",
};
