import React, { useEffect, useState, ReactNode } from "react";
import {
  Filter,
  FetchResourcesHook,
  FetchFiltersHook,
  FilterConfig,
} from "./types";
import { FilterItem } from "./FilterItem";
import { AddedFilters } from "./AddedFilters";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  CardActions,
  Button,
  Collapse,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { isFilterParam } from "components/organisms/Filter/helpers";
import { useSearchParams } from "react-router-dom-v5-compat";
import { serializeSearchParams } from "helpers/serializeSearchParams";

export interface FiltersCardProps {
  fetchHook?: FetchFiltersHook;
  fetchResourcesHook: FetchResourcesHook;
  renderCustomFilterTypes?: Function;
  config?: FilterConfig;
  children?: ReactNode;
}

export const FiltersCard = ({
  config = {},
  fetchHook = (config?: FilterConfig) => ({ data: null, isLoading: null }),
  fetchResourcesHook,
  renderCustomFilterTypes,
  children,
}: FiltersCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState(true);
  const [filters, setFilters] = useState([] as any);
  const { data: filtersResponse, isLoading } = fetchHook(config);
  const hasReset = Boolean(config?.reset);

  // Filter toggles
  useEffect(() => {
    const queryParams: any = [];
    searchParams.forEach((value, key) => {
      if (isFilterParam(key)) queryParams.push({ key, value });
    });

    const output: Filter[] =
      filtersResponse?.map((f: Filter) => {
        const match = queryParams.filter((q: any) => q.key === f.id)?.[0];
        if (match) {
          return {
            ...f,
            value: match.value,
            showToggle: true,
          };
        }
        return f;
      }) || [];
    setFilters(output);
  }, [filtersResponse, searchParams]);

  // Reset filters
  const resetFilters = () => {
    const params: string[] = [];
    searchParams.forEach((value, key) => {
      if (isFilterParam(key)) params.push(key);
    });
    params.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(serializeSearchParams(searchParams));
  };

  const showHeader = hasReset;

  return (
    <Card sx={styles.ctr} className="filters-card">
      {children}
      {showHeader && (
        <CardHeader
          className="filters-header"
          sx={styles.header}
          action={
            <>
              {hasReset && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              )}
              <Button
                variant="text"
                color="inherit"
                onClick={() => setExpanded((current) => !current)}
                endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                sx={styles.hideBtn}
                aria-expanded={expanded}
                id="show-btn"
              >
                {expanded ? "Hide" : "Show"}
              </Button>
            </>
          }
        />
      )}
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        aria-labelledby="show-btn"
        role="region"
      >
        <CardContent sx={styles.content} className="filters-content">
          {isLoading && <CircularProgress color="primary" />}
          {filters.map((item: Filter) => {
            if (item.showToggle && !item.options.HIDDEN) {
              return (
                <FilterItem
                  item={item}
                  key={item.id}
                  fetchResourcesHook={fetchResourcesHook}
                  renderCustomFilterTypes={renderCustomFilterTypes}
                />
              );
            }
            return null;
          })}
        </CardContent>
        <CardActions sx={styles.footer} className="filters-action">
          {Boolean(filters.length) && (
            <>
              <AddedFilters filters={filters} />
            </>
          )}
        </CardActions>
      </Collapse>
    </Card>
  );
};

const styles: SxStyleSheet = {
  ctr: {
    marginBottom: 3,
  },
  header: {
    paddingY: 1,
    paddingX: 2,
    "& .MuiCardHeader-action": {
      display: "flex",
      gap: 1,
      padding: 1,
    },
  },
  hideBtn: {
    minWidth: "70px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    alignItems: "center",
    paddingY: 1,
    paddingX: 2,
  },
  footer: {
    flexDirection: "column",
    alignItems: "flex-end",
    paddingY: 2,
    paddingX: 2,
  },
};
