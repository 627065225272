import React from "react";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer,
  SubNav,
  StandaloneAppWrapper,
  BrandedDocumentTitle,
} from "@akj-dev/shared-components";
import { QueryClientProvider } from "providers/QueryClientProvider";
import { AppStateProvider } from "providers/AppStateProvider/AppStateProvider";
import { UIProvider } from "providers/UIProvider";
import { routes } from "routes";
import { CompatRouter } from "react-router-dom-v5-compat";
import { Routing } from "./providers/Routing";

const AppContainer = () => {
  return (
    <CompatRouter>
      <QueryClientProvider>
        <ProductContainer>
          <BrandedDocumentTitle title="Billing" />
          <SubNav routes={routes} rootTitle="Billing" />
          <UIProvider>
            <ViewContainer>
              <ContentContainer>
                <Routing />
              </ContentContainer>
            </ViewContainer>
          </UIProvider>
        </ProductContainer>
      </QueryClientProvider>
    </CompatRouter>
  );
};

export const App = () => (
  <AppStateProvider>
    <StandaloneAppWrapper root={routes.rootPath}>
      <AppContainer />
    </StandaloneAppWrapper>
  </AppStateProvider>
);
