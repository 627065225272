import React, { useEffect, useState } from "react";
import { Box, TextField, TextFieldProps } from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import FilterBox from "components/organisms/Filter/FilterBox";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format, parseISO, add } from "date-fns";
import { Filter } from "components/organisms/Filter/types";

interface DateRangeFilterProps {
  item: Filter;
  options?: { [key: string]: any };
  selection: string[];
  setSelection: Function;
  addSelection: Function;
  resetSelection: Function;
  close: Function;
}

export default function DateRangeFilter({
  item,
  options,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  ...props
}: DateRangeFilterProps) {
  const val1 = selection?.[0];
  const val2 = selection?.[1];
  const [dateSelectedFrom, setDateSelectedFrom] = useState<Date>(
    val1 ? parseISO(val1) : new Date()
  );
  const [dateSelectedTo, setDateSelectedTo] = useState<Date>(
    val2 ? parseISO(val2) : add(new Date(), { months: 1 })
  );
  const datePattern = options?.DATE_PATTERN || "yyyy-MM-dd";

  useEffect(() => {
    const from = format(dateSelectedFrom, datePattern);
    const to = format(dateSelectedTo, datePattern);

    setSelection([from, to]);
  }, [setSelection, dateSelectedTo, dateSelectedFrom, datePattern]);

  const handleFromDateChange = (date: any) => {
    if (!date) return;
    const parsed = new Date(date);
    if (parsed.toString() !== "Invalid Date") {
      setDateSelectedFrom(date);
    }
  };

  const handleToDateChange = (date: any) => {
    if (!date) return;
    const parsed = new Date(date);
    if (parsed.toString() !== "Invalid Date") {
      setDateSelectedTo(date);
    }
  };

  const renderDatePickerInput = (params: TextFieldProps) => {
    const value = params.inputProps?.value;
    const props = {
      ...params,
      inputProps: {
        ...params.inputProps,
        readOnly: true,
        value: item.valueDisplayFunc ? item.valueDisplayFunc(value) : value,
      },
    };
    return <TextField {...props} />;
  };

  return (
    <Box {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
      >
        <Box sx={styles.filterBody}>
          <DatePicker
            label="From"
            value={dateSelectedFrom}
            onChange={handleFromDateChange}
            renderInput={renderDatePickerInput}
            maxDate={dateSelectedTo}
            inputFormat={datePattern}
            aria-label="Select from date"
          />
          <DatePicker
            label="To"
            value={dateSelectedTo}
            onChange={handleToDateChange}
            renderInput={renderDatePickerInput}
            minDate={dateSelectedFrom}
            inputFormat={datePattern}
            aria-label="Select to date"
          />
        </Box>
      </FilterBox>
    </Box>
  );
}

const styles: SxStyleSheet = {
  filterBody: {
    paddingY: 2,
    paddingX: 2,
    flexDirection: "row",
    rowGap: 1,
    "> div": {
      maxWidth: 175,
      marginRight: 1,
    },
  },
};
