import React, { PropsWithChildren } from "react";
import { Box, List, Grid, Typography, Button } from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { Filter } from "components/organisms/Filter/types";

interface FilterBoxProps {
  item: Filter;
  resetSelection: Function;
  addSelection: Function;
  selection: string[];
  close: Function;
  showReset?: boolean;
  disableApply?: boolean;
}

export default function FilterBox({
  item,
  resetSelection,
  addSelection,
  selection,
  close,
  showReset,
  disableApply,
  children,
  ...props
}: PropsWithChildren<FilterBoxProps>) {
  return (
    <Box {...props}>
      <List disablePadding>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={styles.filterActions}
        >
          <Typography variant="caption" sx={styles.filterTitle}>
            Filter by {item.name}
            {item.options?.MAX_COUNT > 1 && (
              <Typography
                component="p"
                variant="caption"
                sx={styles.maxCountLabel}
              >
                You can select up to {item.options?.MAX_COUNT} items
              </Typography>
            )}
          </Typography>
          <Button
            size="small"
            onClick={() => resetSelection?.()}
            sx={showReset ? styles.show : styles.hidden}
          >
            Reset
          </Button>
        </Grid>
        {children}
        <Grid container sx={styles.filterAdd}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => addSelection()}
            disabled={disableApply ? disableApply : selection.length < 1}
          >
            Apply
          </Button>
          <Button size="small" onClick={() => close()}>
            Cancel
          </Button>
        </Grid>
      </List>
    </Box>
  );
}

const styles: SxStyleSheet = {
  filterActions: {
    padding: 2,
  },
  filterAdd: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 1,
    padding: 2,
  },
  filterTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  maxCountLabel: {
    textTransform: "none",
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "initial",
  },
};
