import { App } from "App";
import React from "react";
import ReactDOM from "react-dom";
import { env } from "services/envService";

(function init() {
  if (
    env.REACT_APP_STAGE !== "development" &&
    env.REACT_APP_STAGE !== "test" &&
    env.REACT_APP_STAGE !== "test-e2e"
  ) {
    const {
      crashAnalyticsServiceInit,
    } = require("./services/crashAnalyticsService");
    crashAnalyticsServiceInit();
  }
  if (env.REACT_APP_STAGE === "test" || env.REACT_APP_STAGE === "test-e2e") {
    const { mockApiServiceInit } = require("./services/mockApiService");
    mockApiServiceInit();
  }
})();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
