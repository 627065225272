/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  ReportDownloadLink,
  GetBilledUsageReportDownloadParams,
} from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Returns a signed URL where the billed report is stored
 */
export const getBilledUsageReportDownload = (
  ban: string,
  params?: GetBilledUsageReportDownloadParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/billed-usage/report/`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetBilledUsageReportDownloadQueryKey = (
  ban: string,
  params?: GetBilledUsageReportDownloadParams
) => [`/customer/${ban}/billed-usage/report/`, ...(params ? [params] : [])];

export type GetBilledUsageReportDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBilledUsageReportDownload>>
>;
export type GetBilledUsageReportDownloadQueryError = ErrorType<unknown>;

export const useGetBilledUsageReportDownload = <
  TData = Awaited<ReturnType<typeof getBilledUsageReportDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetBilledUsageReportDownloadParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBilledUsageReportDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBilledUsageReportDownloadQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBilledUsageReportDownload>>
  > = ({ signal }) =>
    getBilledUsageReportDownload(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBilledUsageReportDownload>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL where the invoice billed report is stored
 */
export const getBilledUsageReportInvoiceDownload = (
  ban: string,
  invoiceSummaryId: number,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/billed-usage/report/${invoiceSummaryId}`,
      method: "get",
      signal,
    },
    options
  );
};

export const getGetBilledUsageReportInvoiceDownloadQueryKey = (
  ban: string,
  invoiceSummaryId: number
) => [`/customer/${ban}/billed-usage/report/${invoiceSummaryId}`];

export type GetBilledUsageReportInvoiceDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBilledUsageReportInvoiceDownload>>
>;
export type GetBilledUsageReportInvoiceDownloadQueryError = ErrorType<unknown>;

export const useGetBilledUsageReportInvoiceDownload = <
  TData = Awaited<ReturnType<typeof getBilledUsageReportInvoiceDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  invoiceSummaryId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBilledUsageReportInvoiceDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBilledUsageReportInvoiceDownloadQueryKey(ban, invoiceSummaryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBilledUsageReportInvoiceDownload>>
  > = ({ signal }) =>
    getBilledUsageReportInvoiceDownload(
      ban,
      invoiceSummaryId,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getBilledUsageReportInvoiceDownload>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(ban && invoiceSummaryId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
