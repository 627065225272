import React from "react";
import { CardContent, Tab, Tabs, Tooltip } from "@mui/material";
import { Link, Outlet, useMatch } from "react-router-dom-v5-compat";
import { getDefaultReportParams } from "helpers/getDefaultReportParams";

export const Reports = () => {
  const match = useMatch("billing/customer/:ban/reports/:report");
  const defaultParams = getDefaultReportParams();
  return (
    <CardContent>
      {/* @ts-ignore */}
      <Tabs value={match?.params.report || ""} variant="outlinedFullWidth">
        <Tooltip title="Provides a summary of charges per phone number for an invoice period.">
          <Tab
            label="Charges"
            value="charges"
            to={`charges?${defaultParams}`}
            component={Link}
          />
        </Tooltip>
        <Tooltip title="Itemised usage for an invoice period.">
          <Tab
            label="Billed Usage"
            value="billed-usage"
            to={`billed-usage?${defaultParams}`}
            component={Link}
          />
        </Tooltip>
        <Tooltip title="Provides the tariff and bolt-on information for an invoice period.">
          <Tab
            label="Services"
            value="live-services"
            to={`live-services`}
            component={Link}
          />
        </Tooltip>
        <Tooltip title="Summary of usage for per mobile number in an invoice period.">
          <Tab
            label="Phone Summary"
            value="phone-summary"
            to={`phone-summary?${defaultParams}`}
            component={Link}
          />
        </Tooltip>
        <Tooltip title="Call profile of a customer's account for an invoice period.">
          <Tab
            label="Plexus Call Profile"
            value="plexus-call-profile"
            to={`plexus-call-profile?${defaultParams}`}
            component={Link}
          />
        </Tooltip>
      </Tabs>
      <Outlet />
    </CardContent>
  );
};
