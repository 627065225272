import React from "react";
import { PhoneSummary } from "api/generated/schemas";
import { ReportTemplate } from "screens/customer/reports/ReportTemplate";
import {
  useGetPhoneSummaryReport,
  useGetPhoneSummaryReportDownload,
} from "api/generated/phone-summary/phone-summary";
import { ReportDateFormats } from "screens/customer/reports/constants/dateFormats";
import { ReportIds } from "screens/customer/reports/constants/reportIds";

const columns: (keyof PhoneSummary)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "pricePlanDescription",
  "numberOfCalls",
  "duration",
  "messages",
  "dataVolume",
  "recurringCharges",
  "totalCredits",
  "netTotal",
  "invoiceNumber",
  "billSequenceNumber",
  "invoiceDate",
  "ban",
  "pricePlanCode",
  "allocationCode1",
  "allocationCode2",
  "allocationCode3",
  "allocationCode4",
  "otherCharges",
  "usageCharges",
  "recurringCredits",
  "otherCredits",
  "usageCredits",
  "amountEligibleForTaxCode1",
  "amountEligibleForTaxCode2",
  "amountEligibleForTaxCode3",
  "amountEligibleForTaxCode4",
  "vatTotal",
  "grossTotal",
  "ukCallsCost",
  "ukCallsVolume",
  "ukCallsDuration",
  "roamedCallsCost",
  "roamedCallsVolume",
  "roamedCallsDuration",
  "ukMessagesCost",
  "ukMessagesVolume",
  "roamedMessagesCost",
  "roamedMessagesVolume",
  "ukMobileDataCost",
  "ukMobileDataVolume",
  "roamedMobileDataCost",
  "roamedMobileDataVolume",
  "purchasesCost",
  "purchasesVolume",
];

const defaultColumns: (keyof PhoneSummary)[] = [
  "invoicePeriod",
  "ben",
  "phoneNumber",
  "username",
  "pricePlanDescription",
  "numberOfCalls",
  "duration",
  "messages",
  "dataVolume",
  "recurringCharges",
  "totalCredits",
  "netTotal",
];

export const PhoneSummaryReport = () => {
  return (
    <ReportTemplate
      id={ReportIds.phoneSummary}
      columns={columns}
      defaultColumns={defaultColumns}
      showCLiFilter={true}
      showPeriodsFilter={true}
      showDateRangeFilter={true}
      reportQueryHook={useGetPhoneSummaryReport}
      reportDownloadHook={useGetPhoneSummaryReportDownload}
      dateFormat={ReportDateFormats.phoneSummary}
    />
  );
};
