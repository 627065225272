import React from "react";
import { LiveService } from "api/generated/schemas";
import { ReportTemplate } from "screens/customer/reports/ReportTemplate";
import {
  useGetLiveServicesReport,
  useGetLiveServicesReportDownload,
} from "api/generated/live-services/live-services";
import { ReportDateFormats } from "screens/customer/reports/constants/dateFormats";
import { ReportIds } from "screens/customer/reports/constants/reportIds";

const columns: (keyof LiveService)[] = [
  "ban",
  "ctn",
  "userName",
  "tariffName",
  "boltOnName",
  "tariffCost",
  "boltOnCost",
  "boltOnEffectiveDate",
  "boltOnExpiryDate",
  "subPartnerNumber",
  "subPartnerName",
  "customerName",
  "status",
  "ben",
  "tariffId",
  "tariffDiscount",
  "parentCtn",
  "sim",
  "contractStartDate",
  "contractEndDate",
  "contractTerm",
  "fullTermEligible",
  "renewalDateFullTerm",
  "inLifeEligible",
  "renewalDateInLife",
  "boltOnId",
  "boltOnDiscount",
];

const defaultColumns: (keyof LiveService)[] = [
  "ban",
  "ctn",
  "userName",
  "tariffName",
  "boltOnName",
  "tariffCost",
  "boltOnCost",
  "boltOnEffectiveDate",
  "boltOnExpiryDate",
];

export const LiveServices = () => {
  return (
    <ReportTemplate
      id={ReportIds.liveServices}
      columns={columns}
      defaultColumns={defaultColumns}
      showCLiFilter={true}
      showPeriodsFilter={false}
      showDateRangeFilter={false}
      reportQueryHook={useGetLiveServicesReport}
      reportDownloadHook={useGetLiveServicesReportDownload}
      dateFormat={ReportDateFormats.liveServices}
    />
  );
};
