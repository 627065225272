import React from "react";
import { Outlet } from "react-router-dom-v5-compat";

export const AppContainer = () => {
  // TODO: Wrap collapsing subnav here?
  return (
    <div>
      <Outlet />
    </div>
  );
};
