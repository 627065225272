/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { ReportDownloadLink, GetPdfInvoiceParams } from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * Returns URL to PDF report
 */
export const getPdfInvoice = (
  ban: string,
  billNumber: string,
  params: GetPdfInvoiceParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/pdf-invoice/${billNumber}`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPdfInvoiceQueryKey = (
  ban: string,
  billNumber: string,
  params: GetPdfInvoiceParams
) => [
  `/customer/${ban}/pdf-invoice/${billNumber}`,
  ...(params ? [params] : []),
];

export type GetPdfInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPdfInvoice>>
>;
export type GetPdfInvoiceQueryError = ErrorType<unknown>;

export const useGetPdfInvoice = <
  TData = Awaited<ReturnType<typeof getPdfInvoice>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  billNumber: string,
  params: GetPdfInvoiceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPdfInvoice>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPdfInvoiceQueryKey(ban, billNumber, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPdfInvoice>>> = ({
    signal,
  }) => getPdfInvoice(ban, billNumber, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPdfInvoice>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(ban && billNumber),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
