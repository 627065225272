import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { env } from "services/envService";

const SENTRY_DSN =
  "https://dcace9b8b16a46a8b22f05d76b7a4363@o240624.ingest.sentry.io/6605319";

export function crashAnalyticsServiceInit() {
  return Sentry.init({
    dsn: SENTRY_DSN,
    environment: env.REACT_APP_STAGE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
