import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";

export const LoadingIndicator = () => {
  return (
    <Box sx={styles.root} aria-label="loading">
      <CircularProgress />
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: {
    m: 2,
  },
};
