/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetPlexusCallReport200,
  GetPlexusCallReportParams,
  ReportDownloadLink,
  GetPlexusCallReportDownloadParams,
} from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getPlexusCallReport = (
  ban: string,
  params?: GetPlexusCallReportParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetPlexusCallReport200>(
    {
      url: `/customer/${ban}/plexus-call-report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPlexusCallReportQueryKey = (
  ban: string,
  params?: GetPlexusCallReportParams
) => [`/customer/${ban}/plexus-call-report`, ...(params ? [params] : [])];

export type GetPlexusCallReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlexusCallReport>>
>;
export type GetPlexusCallReportQueryError = ErrorType<unknown>;

export const useGetPlexusCallReport = <
  TData = Awaited<ReturnType<typeof getPlexusCallReport>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetPlexusCallReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlexusCallReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlexusCallReportQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlexusCallReport>>
  > = ({ signal }) => getPlexusCallReport(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPlexusCallReport>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL to download the Plexus call profile report
 */
export const getPlexusCallReportDownload = (
  ban: string,
  params?: GetPlexusCallReportDownloadParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/plexus-call-report/report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetPlexusCallReportDownloadQueryKey = (
  ban: string,
  params?: GetPlexusCallReportDownloadParams
) => [
  `/customer/${ban}/plexus-call-report/report`,
  ...(params ? [params] : []),
];

export type GetPlexusCallReportDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlexusCallReportDownload>>
>;
export type GetPlexusCallReportDownloadQueryError = ErrorType<unknown>;

export const useGetPlexusCallReportDownload = <
  TData = Awaited<ReturnType<typeof getPlexusCallReportDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetPlexusCallReportDownloadParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlexusCallReportDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPlexusCallReportDownloadQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlexusCallReportDownload>>
  > = ({ signal }) =>
    getPlexusCallReportDownload(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPlexusCallReportDownload>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
