import { Box, Chip } from "@mui/material";
import { isFilterParam } from "components/organisms/Filter/helpers";
import { SxStyleSheet } from "providers/UIProvider";
import React, { useEffect, useState } from "react";
import { Filter, FilterType } from "./types";
import { useSearchParams } from "react-router-dom-v5-compat";
import { serializeSearchParams } from "helpers/serializeSearchParams";

interface AddedFiltersProps {
  filters: Filter[];
}

export const AddedFilters = ({ filters }: AddedFiltersProps) => {
  const [items, setItems] = useState<Filter[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const newItems: any[] = [];
    searchParams.forEach((_, key) => {
      if (!isFilterParam(key)) return;
      const filter = filters?.find(
        (filter: Filter) => key === filter.id && !filter.options.HIDDEN
      );
      if (filter) newItems.push(filter);
    });
    setItems(newItems);
  }, [searchParams, filters]);

  const deleteFilter = (item: Filter) => {
    const params = serializeSearchParams(searchParams);
    delete params[item?.id];
    setSearchParams(params);
  };

  return (
    <Box sx={styles.ctr} className="added-filters">
      {items.map((item) => {
        const separator = item.type === FilterType.DATE_RANGE ? " - " : ", ";
        const name = item.name_short || item.name;
        const values = item.value
          ?.split(",")
          .map((val) => {
            return item.valueDisplayFunc ? item.valueDisplayFunc(val) : val;
          })
          .join(separator);
        const label = `${name} - ${values}`;
        return (
          <Chip
            variant="outlined"
            size="small"
            label={label}
            onDelete={() => deleteFilter(item)}
            sx={styles.chip}
            key={item.id}
          />
        );
      })}
    </Box>
  );
};

const styles: SxStyleSheet = {
  ctr: {
    display: "flex",
    minHeight: 32,
    maxHeight: 32,
    flexDirection: "row",
    overflowY: "hidden",
    overflowX: "auto",
    gap: 1,
    padding: 0.5,
    width: "100%",
    maxWidth: "100%",
    listStyleType: "none",
    marginLeft: "0px",
    marginRight: "0px",
    border: "1px solid",
    borderColor: "#dcdcdc",
    backgroundColor: "#fafafa",
    borderRadius: 16,
    alignContent: "center",
  },
  chip: {
    maxWidth: "100%",
  },
};
