/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { rest } from "msw";
import { faker } from "@faker-js/faker";

export const getGetCustomersMock = () => ({
  _data: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    ban: (() => "687352719")(),
    accountName: (() => "Test Company")(),
  })),
  _page: {
    size: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    current: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  },
});

export const getGetClisMock = () => ({
  _data: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => faker.random.word()),
  _page: {
    size: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    current: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  },
});

export const getGetInvoiceSummaryMock = () => ({
  _data: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    id: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    ban: (() => "687352719")(),
    ben: faker.helpers.arrayElement([faker.random.word(), undefined]),
    invoiceDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    invoiceNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    numberOfMpns: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    totalBeforeVat: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    vat: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    dueDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    billNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
  })),
  _page: {
    size: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    current: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  },
});

export const getGetChargesReportMock = () => ({
  _data: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    invoiceNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    billSequenceNumber: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    invoicePeriod: faker.helpers.arrayElement([faker.random.word(), undefined]),
    invoiceDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    ban: (() => "687352719")(),
    ben: faker.helpers.arrayElement([faker.random.word(), undefined]),
    phoneNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    username: faker.helpers.arrayElement([faker.random.word(), undefined]),
    allocationCode1: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode2: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode3: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode4: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    chargeStartDate: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    chargeEndDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    chargeType: faker.helpers.arrayElement([faker.random.word(), undefined]),
    chargeDescription: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    amount: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    monthlyRate: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  })),
  _page: {
    size: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    current: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  },
});

export const getGetBilledUsageReportMock = () => ({
  _data: Array.from(
    { length: faker.datatype.number({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    id: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    invoiceNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    billSequenceNumber: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    invoicePeriod: faker.helpers.arrayElement([faker.random.word(), undefined]),
    invoiceDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    ban: (() => "687352719")(),
    ben: faker.helpers.arrayElement([faker.random.word(), undefined]),
    phoneNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    username: faker.helpers.arrayElement([faker.random.word(), undefined]),
    allocationCode1: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode2: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode3: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    allocationCode4: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    dialledNumber: faker.helpers.arrayElement([faker.random.word(), undefined]),
    destination: faker.helpers.arrayElement([faker.random.word(), undefined]),
    callDate: faker.helpers.arrayElement([faker.random.word(), undefined]),
    callTime: faker.helpers.arrayElement([faker.random.word(), undefined]),
    timePeriod: faker.helpers.arrayElement([faker.random.word(), undefined]),
    noOfActivities: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    duration: faker.helpers.arrayElement([faker.random.word(), undefined]),
    uplinkValue: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    downlinkValue: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    dataVolume: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    cost: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    transmissionType: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    summaryCategory: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    usageType: faker.helpers.arrayElement([faker.random.word(), undefined]),
    usageSubtype: faker.helpers.arrayElement([faker.random.word(), undefined]),
    pricingFeatureDescription: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    bundleType: faker.helpers.arrayElement([faker.random.word(), undefined]),
    countryOfOrigin: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    networkChargeOrigin: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    roamedCategory: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
    usageDirection: faker.helpers.arrayElement([
      faker.random.word(),
      undefined,
    ]),
  })),
  _page: {
    size: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    current: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
    total: faker.helpers.arrayElement([
      faker.datatype.number({ min: undefined, max: undefined }),
      undefined,
    ]),
  },
});

export const getGetChargesReportDownloadMock = () => ({
  link: faker.helpers.arrayElement([faker.random.word(), undefined]),
});

export const getGetChargesReportInvoiceDownloadMock = () => ({
  link: faker.helpers.arrayElement([faker.random.word(), undefined]),
});

export const getCustomerMSW = () => [
  rest.get("*/customer/search", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetCustomersMock())
    );
  }),
  rest.get("*/customer/:ban/cli", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetClisMock())
    );
  }),
  rest.get("*/customer/:ban/invoice-summary", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetInvoiceSummaryMock())
    );
  }),
  rest.get("*/customer/:ban/charges-report", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetChargesReportMock())
    );
  }),
  rest.get("*/customer/:ban/billed-usage-report", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetBilledUsageReportMock())
    );
  }),
  rest.get("*/customer/:ban/charges-report/report", (_req, res, ctx) => {
    return res(
      ctx.delay(1000),
      ctx.status(200, "Mocked status"),
      ctx.json(getGetChargesReportDownloadMock())
    );
  }),
  rest.get(
    "*/customer/:ban/charges-report/report/:invoiceSummaryId",
    (_req, res, ctx) => {
      return res(
        ctx.delay(1000),
        ctx.status(200, "Mocked status"),
        ctx.json(getGetChargesReportInvoiceDownloadMock())
      );
    }
  ),
];
