/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Vodafone Self Service
 * Vodafone Self Service API.
 * OpenAPI spec version: 1.0.0
 */
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetCustomers200,
  GetCustomersParams,
  GetClis200,
  GetClisParams,
  GetInvoiceSummary200,
  GetInvoiceSummaryParams,
  GetChargesReport200,
  GetChargesReportParams,
  GetBilledUsageReport200,
  GetBilledUsageReportParams,
  ReportDownloadLink,
  GetChargesReportDownloadParams,
} from ".././schemas";
import { ajaxService } from "../../../services/ajaxService/index";
import type { ErrorType } from "../../../services/ajaxService/index";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

export const getCustomers = (
  params?: GetCustomersParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetCustomers200>(
    { url: `/customer/search`, method: "get", params, signal },
    options
  );
};

export const getGetCustomersQueryKey = (params?: GetCustomersParams) => [
  `/customer/search`,
  ...(params ? [params] : []),
];

export type GetCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomers>>
>;
export type GetCustomersQueryError = ErrorType<unknown>;

export const useGetCustomers = <
  TData = Awaited<ReturnType<typeof getCustomers>>,
  TError = ErrorType<unknown>
>(
  params?: GetCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCustomersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomers>>> = ({
    signal,
  }) => getCustomers(params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCustomers>>,
    TError,
    TData
  >(queryKey, queryFn, { ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get all CLIs (phone numbers) associated with the customer

 */
export const getClis = (
  ban: string,
  params?: GetClisParams,
  options?: SecondParameter<typeof ajaxService>
) => {
  return ajaxService<GetClis200>(
    { url: `/customer/${ban}/cli`, method: "get", params },
    options
  );
};

export const getGetClisQueryKey = (ban: string, params?: GetClisParams) => [
  `/customer/${ban}/cli`,
  ...(params ? [params] : []),
];

export type GetClisInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClis>>
>;
export type GetClisInfiniteQueryError = ErrorType<unknown>;

export const useGetClisInfinite = <
  TData = Awaited<ReturnType<typeof getClis>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetClisParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getClis>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetClisQueryKey(ban, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClis>>> = ({
    pageParam,
  }) => getClis(ban, { page: pageParam, ...params }, requestOptions);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getClis>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!ban,
    ...queryOptions,
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getInvoiceSummary = (
  ban: string,
  params?: GetInvoiceSummaryParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetInvoiceSummary200>(
    { url: `/customer/${ban}/invoice-summary`, method: "get", params, signal },
    options
  );
};

export const getGetInvoiceSummaryQueryKey = (
  ban: string,
  params?: GetInvoiceSummaryParams
) => [`/customer/${ban}/invoice-summary`, ...(params ? [params] : [])];

export type GetInvoiceSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoiceSummary>>
>;
export type GetInvoiceSummaryQueryError = ErrorType<unknown>;

export const useGetInvoiceSummary = <
  TData = Awaited<ReturnType<typeof getInvoiceSummary>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetInvoiceSummaryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInvoiceSummary>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInvoiceSummaryQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInvoiceSummary>>
  > = ({ signal }) => getInvoiceSummary(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getInvoiceSummary>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getChargesReport = (
  ban: string,
  params?: GetChargesReportParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetChargesReport200>(
    { url: `/customer/${ban}/charges-report`, method: "get", params, signal },
    options
  );
};

export const getGetChargesReportQueryKey = (
  ban: string,
  params?: GetChargesReportParams
) => [`/customer/${ban}/charges-report`, ...(params ? [params] : [])];

export type GetChargesReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChargesReport>>
>;
export type GetChargesReportQueryError = ErrorType<unknown>;

export const useGetChargesReport = <
  TData = Awaited<ReturnType<typeof getChargesReport>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetChargesReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getChargesReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetChargesReportQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getChargesReport>>
  > = ({ signal }) => getChargesReport(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getChargesReport>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBilledUsageReport = (
  ban: string,
  params?: GetBilledUsageReportParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<GetBilledUsageReport200>(
    {
      url: `/customer/${ban}/billed-usage-report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetBilledUsageReportQueryKey = (
  ban: string,
  params?: GetBilledUsageReportParams
) => [`/customer/${ban}/billed-usage-report`, ...(params ? [params] : [])];

export type GetBilledUsageReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBilledUsageReport>>
>;
export type GetBilledUsageReportQueryError = ErrorType<unknown>;

export const useGetBilledUsageReport = <
  TData = Awaited<ReturnType<typeof getBilledUsageReport>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetBilledUsageReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBilledUsageReport>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBilledUsageReportQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBilledUsageReport>>
  > = ({ signal }) => getBilledUsageReport(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBilledUsageReport>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL to download the report
 */
export const getChargesReportDownload = (
  ban: string,
  params?: GetChargesReportDownloadParams,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/charges-report/report`,
      method: "get",
      params,
      signal,
    },
    options
  );
};

export const getGetChargesReportDownloadQueryKey = (
  ban: string,
  params?: GetChargesReportDownloadParams
) => [`/customer/${ban}/charges-report/report`, ...(params ? [params] : [])];

export type GetChargesReportDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChargesReportDownload>>
>;
export type GetChargesReportDownloadQueryError = ErrorType<unknown>;

export const useGetChargesReportDownload = <
  TData = Awaited<ReturnType<typeof getChargesReportDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  params?: GetChargesReportDownloadParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getChargesReportDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetChargesReportDownloadQueryKey(ban, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getChargesReportDownload>>
  > = ({ signal }) =>
    getChargesReportDownload(ban, params, requestOptions, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getChargesReportDownload>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!ban, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Returns a signed URL to download the charges report for the specific invoice level
 */
export const getChargesReportInvoiceDownload = (
  ban: string,
  invoiceSummaryId: number,
  options?: SecondParameter<typeof ajaxService>,
  signal?: AbortSignal
) => {
  return ajaxService<ReportDownloadLink>(
    {
      url: `/customer/${ban}/charges-report/report/${invoiceSummaryId}`,
      method: "get",
      signal,
    },
    options
  );
};

export const getGetChargesReportInvoiceDownloadQueryKey = (
  ban: string,
  invoiceSummaryId: number
) => [`/customer/${ban}/charges-report/report/${invoiceSummaryId}`];

export type GetChargesReportInvoiceDownloadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChargesReportInvoiceDownload>>
>;
export type GetChargesReportInvoiceDownloadQueryError = ErrorType<unknown>;

export const useGetChargesReportInvoiceDownload = <
  TData = Awaited<ReturnType<typeof getChargesReportInvoiceDownload>>,
  TError = ErrorType<unknown>
>(
  ban: string,
  invoiceSummaryId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getChargesReportInvoiceDownload>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof ajaxService>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetChargesReportInvoiceDownloadQueryKey(ban, invoiceSummaryId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getChargesReportInvoiceDownload>>
  > = ({ signal }) =>
    getChargesReportInvoiceDownload(
      ban,
      invoiceSummaryId,
      requestOptions,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getChargesReportInvoiceDownload>>,
    TError,
    TData
  >(queryKey, queryFn, {
    enabled: !!(ban && invoiceSummaryId),
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
