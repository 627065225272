import { configDev } from "config/config.dev";
import { configE2E } from "config/config.e2e";
import { configProd } from "config/config.production";
import { configUat } from "config/config.uat";
import { env } from "services/envService";

interface EnvConfig {
  baseUrl: string;
}

export function getConfig(): EnvConfig {
  let config: EnvConfig = configDev;
  const stage = env.REACT_APP_STAGE;
  if (stage.includes("e2e")) {
    config = configE2E;
  } else if (stage?.includes("uat")) {
    config = configUat;
  } else if (stage?.includes("production")) {
    config = configProd;
  }
  return config;
}

export const envConfig = getConfig();
