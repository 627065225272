import { getTheme } from "@akj-dev/shared-components";
import { createTheme, SxProps, ThemeProvider, lighten } from "@mui/material";
import React from "react";

export interface SxStyleSheet {
  [key: string]: SxProps;
}

const brandedTheme = getTheme();
const palette = brandedTheme.palette;
export const theme = createTheme({
  ...brandedTheme,
  palette,
  spacing: 8,
  components: {
    MuiButton: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    // TODO: declare new typings. Like https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
    // https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
    // https://github.com/mui/material-ui/issues/21749
    // https://stackoverflow.com/questions/66230799/creating-custom-variants-with-material-ui
    // Problems tho:
    //   Buttons fine: https://codesandbox.io/s/mui-button-variant-mueo80
    //   Tabs not so: https://codesandbox.io/s/mui-tabs-variant-issue-lk9v5c
    //   MUI bug? https://github.com/mui/material-ui/issues/33785
    // @ts-ignore
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
        toolButton: {
          fontWeight: "bold",
          textAlign: "left",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          padding: "16px",
          borderBottom: "1px solid #e0e0e0",
        },
        actions: {
          "& [aria-label='View Columns']": {
            "&:after": {
              content: '"Columns"',
              fontSize: "14px",
              position: "relative",
              left: "5px",
              color: palette.primary.main,
              paddingRight: "5px",
            },
          },
          "& button": {
            color: palette.primary.main,
            padding: "6px 10px",
            borderRadius: "4px",
            border: "1px solid",
            borderColor: palette.primary.light,
          },
        },
      },
    },
    MuiTabs: {
      variants: [
        {
          // @ts-ignore
          props: { variant: "outlinedFullWidth" },
          style: {
            border: `1px solid ${palette.primary.light}`,
            borderRadius: 6,
            marginTop: 24,
            "& .MuiTab-root": {
              minHeight: "auto",
              padding: "8px 16px",
              borderRight: `1px solid ${palette.primary.light}`,
              flexGrow: 1,
              color: palette.primary.main,
              "&:last-child": {
                borderRight: "none",
              },
              "&.Mui-selected": {
                background: lighten(palette.primary.main, 0.8),
                color: palette.primary.dark,
              },
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            minHeight: "auto",
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
        },
      },
    },
  },
});

export const UIProvider = ({ children }: React.PropsWithChildren<any>) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
