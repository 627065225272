import React from "react";
import { useGetInvoiceSummary } from "api/generated/customer/customer";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import { NoResults } from "components/molecules/NoResults";
import { InvoicesTable } from "screens/customer/invoices/InvoicesTable";
import { usePagination } from "components/organisms/PaginatedTable/hooks/usePagination";
import { useBanParam } from "hooks/useBanParam";

export const Invoices = () => {
  const ban = useBanParam();
  const [pagination, setPagination] = usePagination();

  const { data, error, isLoading } = useGetInvoiceSummary(ban, pagination);

  const renderErrorLoading = () => {
    if (error) return <ErrorAlert message={error.message} />;
    if (data?._data?.length === 0) return <NoResults />;
    return null;
  };

  return (
    renderErrorLoading() || (
      <InvoicesTable
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isLoading}
      />
    )
  );
};
