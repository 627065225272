import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom-v5-compat";
import { ArrowBack } from "@mui/icons-material";

interface BackLinkProps {
  to: string;
}

export const BackLink = ({ to }: BackLinkProps) => {
  return (
    <Button startIcon={<ArrowBack />} variant="text" component={Link} to={to}>
      Back
    </Button>
  );
};
