import React, { useState, useEffect } from "react";
import { useGetChargesReportInvoiceDownload } from "api/generated/customer/customer";
import { ErrorAlert } from "components/molecules/ErrorAlert";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { SxStyleSheet } from "providers/UIProvider";
import { useBanParam } from "hooks/useBanParam";

interface DownloadChargesReportProps {
  invoiceId: number;
  downloadHook: typeof useGetChargesReportInvoiceDownload;
  label: string;
}

export const DownloadMenuItem = ({
  invoiceId,
  downloadHook,
  label,
}: DownloadChargesReportProps) => {
  const ban = useBanParam();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [pollingTimeoutError, setPollingTimeoutError] =
    useState<boolean>(false);
  const { error, isFetching, refetch } = downloadHook(ban, invoiceId, {
    query: {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data?.link) {
          setFileUrl(data.link);
          setIsPolling(true);
        }
      },
    },
  });

  useEffect(() => {
    const abortController = new AbortController();

    if (fileUrl) {
      let attempt = 0;
      const maxAttempts = 10;
      const initialDelay = 1000;

      const checkFile = () => {
        fetch(fileUrl, { method: "GET", signal: abortController.signal })
          .then((response) => {
            if (response.ok) {
              setIsPolling(false);
              window.location.href = fileUrl;
            } else {
              attempt += 1;
              if (attempt < maxAttempts) {
                setTimeout(checkFile, initialDelay * Math.pow(2, attempt));
              } else {
                setIsPolling(false);
                setPollingTimeoutError(true);
              }
            }
          })
          .catch((error) => {
            if (error.name !== "AbortError") {
              attempt += 1;
              if (attempt < maxAttempts) {
                setTimeout(checkFile, initialDelay * Math.pow(2, attempt)); // Backoff delay
              } else {
                setIsPolling(false);
                setPollingTimeoutError(true);
              }
            }
          });
      };

      checkFile();

      const timeoutId = setTimeout(() => {
        setIsPolling(false);
        setPollingTimeoutError(true);
      }, 180000); // Stop polling after 3 minutes

      return () => {
        abortController.abort();
        clearTimeout(timeoutId);
      };
    }
  }, [fileUrl]);

  if (error) return <ErrorAlert message={error.message} />;
  if (pollingTimeoutError)
    return <ErrorAlert message="Timed out, try again." />;

  return (
    <MenuItem
      disabled={isPolling || isFetching}
      onClick={() => refetch()}
      sx={styles.root}
    >
      {isPolling || isFetching ? (
        <ListItemIcon>
          <CircularProgress size={24} />
        </ListItemIcon>
      ) : (
        <ListItemText>{label}</ListItemText>
      )}
    </MenuItem>
  );
};

const styles: SxStyleSheet = {
  root: { "&.Mui-disabled": { opacity: 1 } },
};
