import { FilterConfig, FilterType } from "components/organisms/Filter/types";
import { useQuery } from "@tanstack/react-query";
import format from "date-fns/format";

export function useReportFilters(config?: FilterConfig, options = {}) {
  const ban = config?.ban;
  const clis = config?.clis;
  const periods = config?.periods;
  const dateRange = config?.dateRange;
  return useQuery(
    ["reports-filters", { ban, clis, periods }],
    () => {
      const output = [];
      if (clis) {
        output.push({
          id: "f_clis",
          name: "CLI/Identifier",
          name_short: "CLI",
          type: FilterType.LIST,
          resources: `/customer/${ban}/cli`,
          resources_key: "_data",
          showToggle: true,
          values: [],
          options: { SEARCH_BAR: true },
        });
      }
      if (periods) {
        output.push({
          id: "f_periods",
          name: "Invoice Period",
          type: FilterType.LIST,
          resources: `/periods`,
          resources_key: "_data",
          showToggle: true,
          values: [],
          options: {
            MAX_COUNT: config.periodMaxCount,
          },
          valueDisplayFunc: (date: string) => {
            return date && format(new Date(date), "MMMM");
          },
        });
      }
      if (dateRange) {
        output.push({
          id: "f_date_range",
          name: "Custom Dates",
          name_short: "Invoice Period",
          type: FilterType.DATE_RANGE,
          showToggle: true,
          values: [],
          options: { DATE_PATTERN: "yyyy-MM-dd" },
          valueDisplayFunc: (date: string) => {
            if (!date) return date;
            try {
              const parsed = new Date(date);
              if (parsed.toString() !== "Invalid Date") {
                return format(new Date(date), "dd/MM/yyyy");
              }
            } catch (e) {
              return date;
            }
            return date;
          },
        });
      }
      return output;
    },
    { ...options }
  );
}
