import { SxStyleSheet } from "providers/UIProvider";
import React, { useState } from "react";
import { Box, Button, Menu } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DownloadMenuItem } from "./DownloadMenuItem";
import { useGetChargesReportInvoiceDownload } from "api/generated/customer/customer";
import { useGetBilledUsageReportInvoiceDownload } from "api/generated/billed-usage/billed-usage";
import { useGetPdfInvoice } from "api/generated/pdf-invoice/pdf-invoice";
import { PreviewMenuItem } from "screens/customer/invoices/DownloadOptions/PreviewMenuItem";

interface DownloadOptionsProps {
  invoiceId: number;
  billNumber: string;
  ben: string;
}

export const DownloadOptions = ({
  invoiceId,
  billNumber,
  ben,
}: DownloadOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={styles.root}>
      <Button
        id="download-button"
        aria-controls={open ? "download-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        startIcon={<ExpandMore />}
        onClick={handleClick}
      >
        Download
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "download-button" }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <PreviewMenuItem
          billNumber={billNumber}
          ben={ben}
          downloadHook={useGetPdfInvoice}
          label="Invoice PDF"
        />
        <DownloadMenuItem
          invoiceId={invoiceId}
          downloadHook={useGetChargesReportInvoiceDownload}
          label="Charges Report CSV"
        />
        <DownloadMenuItem
          invoiceId={invoiceId}
          downloadHook={useGetBilledUsageReportInvoiceDownload}
          label="Billed Usage Report CSV"
        />
      </Menu>
    </Box>
  );
};

const styles: SxStyleSheet = {
  root: { display: "flex", justifyContent: "flex-end" },
};
