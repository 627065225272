import { useSearchParams } from "react-router-dom-v5-compat";
import { serializeSearchParams } from "helpers/serializeSearchParams";
import { usePrevious } from "hooks/usePrevious";
import { GetChargesReportParams } from "api/generated/schemas";
import { useMemo } from "react";

type Filters = Pick<
  GetChargesReportParams,
  "periods" | "start" | "end" | "clis"
>;

export const useExclusiveFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(
    () => serializeSearchParams(searchParams),
    [searchParams]
  );

  // Filters
  let filters: Filters = useMemo(
    () => ({
      periods: params.f_periods?.split(","),
      start: params.f_date_range?.split(",")[0] as unknown as Date,
      end: params.f_date_range?.split(",")[1] as unknown as Date,
      clis: params.f_clis?.split(","),
    }),
    [params]
  );

  const prevParams = usePrevious(params);

  // Reports must have periods or date range selected
  if (prevParams?.f_periods && params.f_date_range) {
    const { f_periods, ...rest } = params;
    setSearchParams(rest);
  }
  if (prevParams?.f_date_range && params.f_periods) {
    const { f_date_range, ...rest } = params;
    setSearchParams(rest);
  }

  return filters;
};
